import React from 'react'
import {Modal} from 'react-bootstrap';

import {logout} from '../Actions/LoginActions'

import { walletControl,removeWallteAddress } from '../Lib/localStorage';

import { useDispatch } from 'react-redux';
import { WALLET_ADDRESS } from '../constants';

function LogoutModal({show,handleClose}) {
 const dispatch =  useDispatch()


 const handlelogout = async()=>{
    await logout();
    window.location.href = window.location.origin+"/login";
    dispatch(walletControl('disconnect'))
    dispatch({
      type:WALLET_ADDRESS,
      payload:''
    })
    removeWallteAddress()
    handleClose()
   }


  return (
   <>
   <Modal centered className='cmn_modal'
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        
        <Modal.Body>
            <div className='cmn_modal_header d-flex justify-content-between align-items-center'>
                <p className='cmn_modal_title'>Logout</p>
                <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark"/></button>
            </div>

            <div className='mt-3'>
            
            <p className='dash_graymed_text'>Are you sure to logout?</p>
                <button className='orange_small_primary mt-5' onClick={()=>{handlelogout()}}>Logout</button>
            </div>
         
        </Modal.Body>
       
      </Modal>

   </>
  )
}

export default LogoutModal