import axios from 'axios';
import { API_URL } from '../Config/env';
import * as admin from "../Routes/AdminRoutes";
import * as cms from "../Routes/CmsRoutes"
// import { Decryptdata } from '../Config/encrypt_decrypt';


export const addFee = async (data) => {
    console.log("addIpAddress", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + admin.addFee,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
   
    }
    catch (e) {
      console.log("error", e);
    }
  }
  
  export const editFee = async (data) => {
    console.log("addIpAddress", data);
    try {
     
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + admin.editFee,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
    }
    catch (e) {
      console.log("error", e);
    }
  } 
  
  export const deleteFee = async (data) => {
    console.log("deleteIpAddress", data);
    try {
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + admin.deleteFee,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
    }
    catch (e) {
      console.log("error", e);
    }
  }
  
  export const getFees = async (data) => {
    // console.log("data" , data);
    try {
      // console.log("headers" , headers);
      let respData = await axios.get(API_URL + admin.getFee)
  
      console.log("respdata", respData);
      return {
        data: respData
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }

  export const getCmslists = async (data) => {
    // console.log("data" , data);
    try {
      let respdata = await axios({
        'method': 'GET',
        'url': API_URL + cms.cmslist,
        
      });
      console.log("response", respdata);
      // let resp = Decryptdata(respdata)
      // console.log("respnsedss",resp);
      return {
        data: respdata
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }

  export const getCmsEdit = async (data) => {
    console.log("data" , data);
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + cms.cmsedit,
        'data':data
        
      });
      console.log("response", respdata);
      return {
        data: respdata
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }

  export const getcmslistData = async (data) => {
    console.log("data" , data);
    try {
      let respdata = await axios({
        'method': 'GET',
        'url': API_URL + cms.cmslistdata,
        'params' : data
        
      });
      console.log("response", respdata);
      return {
        data: respdata
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }

  export const getsociallists = async (data) => {
    console.log("data" , data);
    try {
      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + cms.addsociallink,
        'data' : data
        
      });
      console.log("response", respdata);
      return {
        data: respdata
      }
    }
    catch (err) {
      return {
        error: err
      }
    }
  }