import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import ReactDatatable from '@ashvin27/react-datatable';
import EditCmsModal from '../Modals/Editcmsmodal';
import DeleteFeeModal from '../Modals/DeleteFeeModal';
import { NavLink } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';


//Actions
import { getFees, deleteFee, getCmslists } from '../Actions/FeeSettingsActions';
import { useEffect } from 'react';


function Contentmanagement() {
    // edit modal
    const [showEdit, setShowEdit] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    //   delete modal

    const [showDelete, setShowDelete] = useState(false);

    const [getFee, setGetFee] = useState([])
    const [deleteId, setDeleteId] = useState('')
    const [editData, setEditData] = useState({})



    const [active, setActive] = useState('buy')
    const state = {
        records: [
            {
                "feename": "LEO",
                "bnbvalue": "5",
                "tokenvalue": "0"
            },
            {
                "feename": "DAS",
                "bnbvalue": "2",
                "tokenvalue": "1"
            },



        ]
    }

    const columns = [
   
        {
            key: "feeName",
            text: "Identifier",
            className: "feename",
            align: "center",
            sortable: false,
            cell: (record) => {
                return (
                   <div>{record.identifier}</div>
                )
            },
        },
        {
            key: "currencyvalue",
            text: "Title",
            className: "bnbvalue",
            align: "center",
            sortable: false,
            cell: (record) => {
                return (
                    <div dangerouslySetInnerHTML={{__html:record?.Title.length > 15 ? record?.Title.slice(0,15)+"..." : record?.Title}} />
                )
            },
        },
        {
            key: "tokenvalue",
            text: "Content",
            className: "bnbvalue",
            align: "center",
            sortable: false,
            cell: (record) => {
                return (
                    <>{
                        record?.Content.length > 0 ?
                        <div dangerouslySetInnerHTML={{__html : record?.Content.length > 30 ?  record?.Content.slice(0,30)+"...." : record?.Content } }/>
                        :"-No content-"
                    }
               
                  </> )
            },

        },
        {
            key: "action",
            text: "Action",
            className: "activity",
            align: "center",
            sortable: false,
            cell: (record) => {
                return (
                    <div className='d-flex justify-content-center align-items-center gap-2'>
                        <button className='cmn_plain_btn' onClick={() => { setEditData(record); handleShowEdit() }}><img src={require('../assets/images/editer.svg').default}
                            className='img-fluid table_activity_img' /> </button>
                        {/* <button className='cmn_plain_btn' onClick={() => { setDeleteId(record._id); handleShowDelete(); }}>
                            <img src={require('../assets/images/trash.svg').default} className='img-fluid table_activity_img' /> </button> */}
                    </div>
                )
            }
        },
    ];

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Emailtemplates",
        no_data_text: "No Email Templates found!",
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: false,
        show_filter: false,
        show_pagination: true,
        show_info: false,
    };

    const extraButtons = [
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
        },
        {
            className: "btn btn-primary buttons-pdf",
            title: "Export TEst",
            children: [
                <span>
                    <i className="glyphicon glyphicon-print fa fa-print" aria-hidden="true"></i>
                </span>
            ],
            onClick: (event) => {
                console.log(event);
            },
            onDoubleClick: (event) => {
                console.log("doubleClick")
            }
        },
    ]


    useEffect(() => {
        GetCmscontents()
    }, [])


    const GetCmscontents = async () => {
        let get = await getCmslists();
        console.log("getCmslists", get);
        if (get?.data?.data?.status == true) {
            setGetFee(get?.data?.data?.data);
            console.log("getFee_getFee", get?.data?.data?.data)
        }
    }





    return (
        <>
            <Container fluid className='common_bg position-relative content_manage'>
                <div className='liner'></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12}>
                        <Header title={'Content Management'} />
                        <div className='setting_tab_holder mt-5 pe-2'>
                            <Row>
                                <Col sm={6} className='d-flex gap-3 mb-3 mb-sm-0'>

                                </Col>
                                 <Col sm={6} className='text-end'>
                                    {/* <NavLink to='/addFee' className='sidebar_links'>
                                        <button className='orange_small_primary'>Add</button>
                                    </NavLink> */}
                                </Col> 
                            </Row>
                        </div>

                        <div className='common_page_scroller mt-4'>
                            <div className='exchange_table_holder dashboard_box rounded-3'>
                                <ReactDatatable
                                    config={config}
                                    records={getFee}
                                    columns={columns}
                                    extraButtons={extraButtons}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>

            {/* modals */}

            {showEdit && <EditCmsModal show={showEdit} handleClose={() => { handleCloseEdit() }} getSettings={() => { GetCmscontents() }} editData={editData} />}

        </>
    )
}

export default Contentmanagement