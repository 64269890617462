import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router-dom';

//Actions
import { resetPassword } from "../Actions/LoginActions";

//validations
import { validatepassword } from '../Validations/LoginValidate'

function Resetnewpassword() {
    const history = useHistory()
    const location = useLocation()
    const [pwdeye, setPwdeye] = useState(false)
    const [confirmpwdeye, setConfirmpwdeye] = useState(false)
    const [password, setPassword] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const [errors, setErrors] = useState('')


    useEffect(() => {
        console.log("path", location)
    }, [])


    const handleSubmit = async () => {
        let payload = {
            password: password,
            confirmPass: confirmPass
        }
        let validate = validatepassword(payload);

        let data = {
            password: password,
            authToken: location.pathname.split('/')[2]
        }

        if (validate.isValid) {
            var resetPass = await resetPassword(data);
            console.log("resetPass", resetPass)
            if (resetPass?.data?.data?.status == "false" || !resetPass?.data?.data?.status) {
                setErrors(resetPass?.data?.data?.errors)
            } else {
                toast.success(resetPass?.data?.data?.message)
                console.log("resetPass_resetPass", resetPass);
                history.push("/login")

            }
        }
        else {
            setErrors(validate?.errors)
        }
    }




    return (
        <>
            <Container fluid className='common_bg login_bg position-relative'>
                <div className='logo_sec'>
                    <img src={require('../assets/images/headerlogo.png')} className="img-fluid main_logo" />

                </div>

                <Row className='justify-content-center align-items-center row_card'>

                    <Col xl={12} lg={12} md={12} sm={12}>
                        <div className='dashboard_box p-4'>
                            <div className='profile_holder'>
                                <p className='header_title_big'>Reset Password</p>
                                <hr className='grey_hr' />
                            </div>

                            <div className='profile_holder'>
                                <Row>


                                    <Col lg={12} md={12} sm={12} className='mb-4'>
                                        <div className='rp_singleinput_holder'>
                                            <p className='rp_label mb-2'>New Password</p>
                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                <input type={!pwdeye ? "password" : "text"} placeholder='Enter Password' className='rp_singleInput flex-grow-1'
                                                    onChange={(e) => { setPassword(e?.target?.value) }} />

                                                <i class={!pwdeye ? 'fa-solid fa-eye-slash' : "fa-solid fa-eye"} onClick={() => setPwdeye(!pwdeye)} />
                                            </div>
                                            <span className="text-danger f-12 d-block text-left">{errors?.password}</span>

                                        </div>

                                    </Col>

                                    <Col lg={12} md={12} sm={12} className='mb-4'>
                                        <div className='rp_singleinput_holder'>
                                            <p className='rp_label mb-2'>Confirm Password</p>
                                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                                <input type={!confirmpwdeye ? "password" : "text"} placeholder='Enter Password' className='rp_singleInput flex-grow-1'
                                                    onChange={(e) => { setConfirmPass(e?.target?.value) }} />

                                                <i class={!confirmpwdeye ? 'fa-solid fa-eye-slash' : "fa-solid fa-eye"} onClick={() => setConfirmpwdeye(!confirmpwdeye)} />
                                            </div>
                                            <span className="text-danger f-12 d-block text-left">{errors?.confirmPass}</span>

                                        </div>
                                        <div className='mt-3 text-end'>
                                            <Link to="/login" className='link_theme'>Back to Login?</Link>
                                        </div>

                                    </Col>


                                </Row>
                                <div className='text-center'>
                                    <button className='orange_small_primary' onClick={() => { handleSubmit() }}>Submit</button>
                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default Resetnewpassword