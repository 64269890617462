
export const PROXY = {
	56: "", //bsc mainnet

    97: '0xB9E67D7c3b6957CacF9D835691a45BF62260F7a5',//"0x8eb14f50074D487c62F646F53F629d092B25c0fC", // new bsc testnet

    137: "", //polygon mainnet

	1: "",

	3: "",

	43113 :"", // avalanche testnet 

	42161 : ""  //arbitrum
};