const BASE_URL = "/cms";

// const SETTING_URL = '/settings'

export const cmslist = BASE_URL  + "/cmslist";

export const cmsedit = BASE_URL  + "/cmsedit";

export const cmslistdata = BASE_URL  + "/cmslistdata";

export const addsociallink = BASE_URL  + "/addsociallink";
