import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { Col, Container, Row, Modal } from 'react-bootstrap'
import Header from '../../Components/Header'
import Sidebar from '../../Components/Sidebar';
import { useHistory } from "react-router-dom"

import { IMG_URL } from '../../Config/env'
import { getsociallists } from '../../Actions/FeeSettingsActions';
function Editsociallink({ show, handleClose, editData, getSettings }) {
    console.log("EditCmscontent", show, editData);

    const [mapimage, setMapimage] = useState([])
    const [formData, setFormData] = useState({})
    console.log("formsDats", formData);
    const history = useHistory()
    const [MyContent, setMyContent] = useState("")
    console.log("MyContent", MyContent);

    useEffect(() => {
        // handleedit()
        setFormData(editData)
        GetCmsAll()
    }, [])


    const GetCmsAll = async () => {
        try {
            let resp = await getsociallists({ data: editData?._id,action :"oneData" })
            console.log("response", resp.data.data.data[0].image);
            setFormData({...formData,...{["file"]:resp.data.data.data[0].image}})
        } catch (error) {
            console.log("error", error);
        }
    }

    const handleedit = async () => {
        try {
            if(formData.file){
                var Formdatas = new FormData()
                Formdatas.append("link", formData.link)
                Formdatas.append("id", formData._id)
    
                Formdatas.append("title", formData.title)
                Formdatas.append("file", formData.file )
                Formdatas.append("action", "edit")
            }
            else{
                var Formdatas = new FormData()
                Formdatas.append("link", formData.link)
                Formdatas.append("id", formData._id)
    
                Formdatas.append("title", formData.title)
                Formdatas.append("image", formData.image)
                Formdatas.append("action", "edit")
            }
           


            let result = await getsociallists(Formdatas);
            console.log("result", result?.data?.data?.msg)
            if (result?.data?.data?.status == true) {
                toast.success(result?.data?.data?.msg)
                history.push('/sociallinklist')
                handleClose();
                getSettings()
            }
            else {
                toast.error(result?.data?.data?.msg)

            }
        } catch (error) {
            console.log("error", error);
        }

    }

    const handleChange = async (e) => {
        console.log(e.target.files);
        try {
            console.log("valuess", e.target.value);

            const { id } = e.target
            if (id == "file") {
                let file = e.target.files[0]
                setFormData({ ...formData, ...{ ["file"]: file } })
            }
            else {
                const { id, value } = e.target
                console.log("idvalues", id, value);
                setFormData({ ...formData, ...{ [id]: value } })
            }
        } catch (error) {
            console.log("error", error);
        }


    }


    return (
        <>
            <Modal centered className='cmn_modal'
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <div className='cmn_modal_header d-flex justify-content-between align-items-center'>
                        <p className='cmn_modal_title'>Edit Sociallink</p>
                        <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark" /></button>
                    </div>



                    <div className='mt-4 ms-3'>



                        <div className='rp_singleinput_holder mb-3'>
                            <p className='rp_label mb-2'>Title</p>
                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                <input type='text' placeholder='title' className='rp_singleInput flex-grow-1'  id="title"
                                    value={formData?.title} onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>

                        <div className='rp_singleinput_holder mb-3'>
                            <p className='rp_label mb-2'>Link</p>
                            <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                <input type='text' placeholder='link' className='rp_singleInput flex-grow-1'  id="link"
                                    value={formData?.link} onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                        {/* <div className='rp_singleinput_holder mb-3'>
    <p className='rp_label mb-2'>Content</p>
    <CKEditor initData="<p>This is an example CKEditor 4 WYSIWYG editor instance.</p>" />
</div> */}

                        <div className='rp_singleinput_holder mb-4'>

                            <p className='rp_label mb-2'>Image</p>

                            <div className='browseinput'>





                                <input type='file' id="file" className='form-control' multiple onChange={(e) => handleChange(e)} />
                                <button className='orange_small_primary'>Upload Images</button>
                                {

                                    <>

                                        <img
                                            className='mt-3 objectimg'
                                            src={
                                                typeof formData?.file == "object"
                                                    ? window.URL.createObjectURL(formData?.file)
                                                    :`${IMG_URL}/sociallink/${formData?.image}`

                                            }
                                            width={100}
                                        />



                                    </>}
                                <br />
                            </div>



                            {/* <img src={file} className='img-fluid fileimg mt-3' /> */}
                        </div>




                        <div className='text-center mt-4 pb-3'>
                            <button className='orange_small_primary' onClick={() => { handleedit() }}>Submit</button>
                        </div>
                    </div>





                </Modal.Body>

            </Modal>


        </>
    )
}

export default Editsociallink