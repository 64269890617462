import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import toast, { Toaster } from 'react-hot-toast';
import { useLocation,useHistory } from 'react-router-dom'

//Actions
import { addFee } from '../Actions/FeeSettingsActions';

//validations
import { addFeevalidation } from '../Validations/SettingsValidate';

function AddFee() {

    const [feeName, setFeeName] = useState('')
    const [currencyvalue, setCurrencyvalue] = useState('')
    const [tokenvalue, setTokenvalue] = useState('')
    const [errors, setErrors] = useState('')

    const history = useHistory()
    
    const handleadd = async () => {
        console.log("handleadd");
        let payload = {
            feeName: feeName,
            currencyvalue: currencyvalue,
            tokenvalue: tokenvalue
        };
        console.log("payload", payload);
        let validate = addFeevalidation(payload);
        if (validate?.isValid) {
            let feeresult = await addFee(payload);
            console.log("feeresult", feeresult);
            toast.success(feeresult?.data?.data?.message)
            // this.props.onDismiss();
            history.push('/feeSettings')
        }
        else {
            setErrors(validate.errors)
        }

    }



    return (
        <>
            <Container fluid className='common_bg position-relative'>
                <div className='liner'></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12}>
                        <Header title={'Add Fee'} />
                        <div className='mt-5 profile_holder ps-lg-3'>
                            <Row>
                                <Col lg={7} className='mb-4'>
                                    <div className='rp_singleinput_holder'>
                                        <p className='rp_label mb-2'>Fee Name</p>
                                        <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                            <input type='text' placeholder='Fee Name' className='rp_singleInput flex-grow-1' name='feeName'
                                                value={feeName} onChange={(e) => { setFeeName(e?.target?.value) }} />

                                        </div>
                                        <span className="text-danger f-12 d-block text-left">{errors?.feeName}</span>

                                    </div>
                                </Col>

                                <Col lg={7} className='mb-4'>
                                    <div className='rp_singleinput_holder'>
                                        <p className='rp_label mb-2'>BNB Value</p>
                                        <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                            <input type='text' placeholder='BNB Value' className='rp_singleInput flex-grow-1' name='currencyvalue'
                                                value={currencyvalue} onChange={(e) => { setCurrencyvalue(e?.target?.value) }} />

                                        </div>
                                        <span className="text-danger f-12 d-block text-left">{errors?.currencyvalue}</span>

                                    </div>
                                </Col>
                                <Col lg={7} className='mb-4'>
                                    <div className='rp_singleinput_holder'>
                                        <p className='rp_label mb-2'>Token Value</p>
                                        <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                            <input type='text' placeholder='Token Value' className='rp_singleInput flex-grow-1' name='tokenvalue'
                                                value={tokenvalue} onChange={(e) => { setTokenvalue(e?.target?.value) }} />

                                        </div>
                                        <span className="text-danger f-12 d-block text-left">{errors?.tokenvalue}</span>

                                    </div>
                                </Col>
                            </Row>
                            <button className='orange_small_primary' onClick={() => { handleadd() }}>Submit</button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AddFee