import axios from 'axios';
import Cookies from 'universal-cookie';
import { API_URL } from '../Config/env';
import * as admin from "../Routes/AdminRoutes";
const cookies = new Cookies();

export const login = async (data) => {
  console.log("login data" , data);
  try {

      let respdata = await axios({
        'method': 'POST',
        'url': API_URL + admin.loginApi,      
         data,
      });
      console.log("response", respdata);
      if (respdata?.data?.status) {
        localStorage.setItem('auth',respdata?.data?.accessToken)
        cookies.set('cryp-launch-admin-email', respdata.data.email, { path: '/' }, { expires: new Date(Date.now() + 600000) });
        cookies.set('cryp-launch-admin-Datas', respdata.data, { path: '/' }, { expires: new Date(Date.now() + 600000) })
      }
      return {
        data: respdata
      }
    }
   
  catch (e) {
    console.log("login_error", e);
  }
}

export const logout = async () => {
  cookies.remove("cryp-launch-admin-email");
  cookies.remove("cryp-launch-admin-Datas");
  localStorage.removeItem("accountInfo");
  sessionStorage.clear();
}


export const forgetPassword = async (data) => {
    console.log("forgetPassword", data);
    try {

        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + admin.forgetPassword,
           data,
        });
        console.log("response", respdata);
        return {
          data: respdata,
          // message:respdata.data.data.message
        }
    
    }
    catch (e) {
      console.log("error", e);
    }
  }
  
  export const resetPassword = async (data) => {
    console.log("resetPassword", data);
    try {
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + admin.resetPassword,
          data
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
    }
    catch (e) {
      console.log("error", e);
    }
  }
  
  export const profileSettings = async (data) => {
    console.log("profileSettings", data);
    try {
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + admin.profileSettings,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
    }
    catch (e) {
      console.log("error", e);
    }
  }
  
  export const sentVerifyLink = async (data) => {
    console.log("profileSettings", data);
    try {
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + admin.sentVerifyLink,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
    }
    catch (e) {
      console.log("error", e);
    }
  }
  
  export const verifyNewEmail = async (data) => {
    console.log("profileSettings", data);
    try {
    
        let respdata = await axios({
          'method': 'POST',
          'url': API_URL + admin.verifyNewEmail,
          data,
        });
        console.log("response", respdata);
        return {
          data: respdata
        }
    }
    catch (e) {
      console.log("error", e);
    }
  }
  