import React from 'react'
import {Modal} from 'react-bootstrap';

function WalletDetail({show,handleClose}) {
  return (
    <>
    <Modal centered className='cmn_modal'
       show={show}
       onHide={handleClose}
       backdrop="static"
       keyboard={false}
     >
       
       <Modal.Body>
           <div className='cmn_modal_header d-flex justify-content-between align-items-center'>
               <p className='cmn_modal_title'>View Details</p>
               <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark"/></button>
           </div>

           <div className='mt-4'>
           <div className='rp_singleinput_holder mb-3'>
                   <p className='rp_label mb-1'>Name</p>
                   <p className='dash_graymed_text'>Demo</p>
                 
               </div>

               <div className='rp_singleinput_holder mb-3'>
                   <p className='rp_label mb-1'>Email</p>
                   <p className='dash_graymed_text'>demo@gmail.com</p>
                 
               </div>

               <div className='rp_singleinput_holder mb-3'>
                   <p className='rp_label mb-1'>Public Key</p>
                   <p className='dash_graymed_text break_text'>uhfuhsidfiosdfisdfosdofdnfdfoidfmosdfosfoasikdpmfoisomifsfsfsgsdgfhhfgh sfgsdojfnojfajfoijasfomasoifoasdffiopsaifopsfsfsdzgsdgsdg</p>
                 
               </div>
           </div>
        
       </Modal.Body>
      
     </Modal>
   </>
  )
}

export default WalletDetail