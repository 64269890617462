import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { Col, Container, Row } from 'react-bootstrap'
import Header from '../../Components/Header'
import Sidebar from '../../Components/Sidebar';
import { useHistory } from "react-router-dom"

import { IMG_URL } from '../../Config/env'
import { getsociallists } from '../../Actions/FeeSettingsActions';
function Editsociallink({ show, handleClose, editData, getSettings }) {
    console.log("EditCmscontent", show, editData);


    const [formData, setFormData] = useState({})
    console.log("formsDats", formData);
    const history = useHistory()
    const [MyContent, setMyContent] = useState("")
    console.log("MyContent", MyContent);


    useEffect(() => {

        GetCmsAll()
    }, [])


    const GetCmsAll = async () => {
        // try {
        //     let resp = await getcmslistData({ data: editData?.identifier })
        //     console.log("response", resp.data.data.data.image);
        //     setMapimage(resp.data.data.data.image)
        // } catch (error) {
        //     console.log("error", error);
        // }
    }

    const handleedit = async () => {
        try {
            let Formdatas = new FormData()
            Formdatas.append("link", formData.link)
            Formdatas.append("title", formData.title)
            Formdatas.append("file", formData.file)
            Formdatas.append("action", "add")


            let result = await getsociallists(Formdatas);
            console.log("result", result?.data?.data?.msg)
            if (result?.data?.data?.status == true) {
                toast.success(result?.data?.data?.msg)
                history.push('/sociallinklist')
              
            }
            else {
                toast.error(result?.data?.data?.msg)

            }
            
        } catch (error) {
            console.log("error", error);
        }

    }

    const handleChange = async (e) => {
        console.log(e.target.files);
        try {
            console.log("valuess", e.target.value);

            const { id } = e.target
            if (id == "file") {


                let file = e.target.files[0]

                setFormData({ ...formData, ...{ ["file"]: file } })
            }
            else {
                const { id, value } = e.target
                console.log("idvalues", id, value);
                setFormData({ ...formData, ...{ [id]: value } })
            }
        } catch (error) {
            console.log("error", error);
        }


    }


    return (
        <>

            <Container fluid className='common_bg position-relative'>
                <div className='liner'></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12}>
                        <Header title={'Add Social'} />

                        <Row>
                            <Col xxl={7}>
                                <button className='orange_small_primary mt-4' onClick={() => history.goBack()}>Back</button>


                                <div className='mt-4 ms-3'>

                                    <div className='rp_singleinput_holder mb-3'>
                                        <p className='rp_label mb-2'>Title</p>
                                        <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                            <input type='text' placeholder='Identifier' className='rp_singleInput flex-grow-1' id='title'
                                                value={formData?.title} onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                    </div>


                                    <div className='rp_singleinput_holder mb-3'>
                                        <p className='rp_label mb-2'>Link</p>
                                        <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                                            <input type='text' placeholder='title' className='rp_singleInput flex-grow-1' id="link"
                                                value={formData?.link} onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                    </div>


                                    <div className='rp_singleinput_holder mb-4'>

                                        <p className='rp_label mb-2'>Image</p>

                                        <div className='browseinput'>





                                            <input type='file' id="file" className='form-control' onChange={(e) => handleChange(e)} />
                                            <button className='orange_small_primary'>Upload Images</button>
                                            {

                                                <>

                                                    <img
                                                        className='mt-3 objectimg'
                                                        src={
                                                             typeof formData?.file == "object"
                                                                ? window.URL.createObjectURL(formData?.file)
                                                                : ""
                                                            
                                                        }
                                                        width={100}
                                                    />



                                                </>}
                                            <br />
                                        </div>



                                        {/* <img src={file} className='img-fluid fileimg mt-3' /> */}
                                    </div>




                                    <div className='text-center mt-4 pb-3'>
                                        <button className='orange_small_primary' onClick={() => { handleedit() }}>Submit</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>






        </>
    )
}

export default Editsociallink