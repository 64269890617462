import React from 'react'
import {Modal} from 'react-bootstrap';
import { CHAINS } from '../Config/env'
import { setChainId, SwitchChain, SwitchChainS } from '../hooks/useWeb3';
// import { IoMdClose } from "react-icons/io";
import { NETWORK_SWITCH } from '../constants';
import { useDispatch, useSelector } from 'react-redux';


function NetworkModal({show,handleClose}) {

  const dispatch = useDispatch()


  return (
   <>
   <Modal centered className='cmn_modal'
    size="sm"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        
        <Modal.Body>
            <div className='cmn_modal_header d-flex justify-content-between align-items-center'>
                <p className='cmn_modal_title'>Network</p>
                <button className='cmn_modal_closer rounded-5' onClick={handleClose}><i class="fa-solid fa-xmark"/></button>
            </div>

            <p className='modal_innerhead mt-5'>Select a Network</p>
            <div className='network_sec'>

            {CHAINS.map((e, i) => (
              <div><button className={`walletrow text-center`} onClick={async () => {
                let SwitchRes = await SwitchChainS(i);
                if (SwitchRes) {
                  setChainId(i);
                  handleClose();
                  dispatch({
                    type: NETWORK_SWITCH,
                    payload: i.toString()
                  })
                }

              }} ><img src={e.IMAGE} className={`img-fluid networkmodalimg`} alt='' /> 
              <p className={`label_wallet mt-3`}>{e.NAME}</p>
              </button></div>

            ))}


            {/* <div className={`walletrow text-center`} onClick={handleClose}>
            <img src={require('../assets/images/bsc.png')} alt='metamask' className={`img-fluid networkmodalimg`} />
            <p className={`label_wallet mt-3`}>BNB Chain</p>
          </div>
          <div className={`walletrow text-center`} onClick={handleClose}>
            <img src={require('../assets/images/avalanche.png')} alt='metamask' className={`img-fluid networkmodalimg`} />
            <p className={`label_wallet mt-3`}>Avalanche</p>
          </div> */}
   
          </div>
         
        </Modal.Body>
       
      </Modal>

   </>
  )
}

export default NetworkModal