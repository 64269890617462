import axios from 'axios';
import { API_URL } from '../Config/env';
import * as admin from "../Routes/AdminRoutes";
import { CHAINS } from '../Config/env';
import { getChainId } from '../hooks/useWeb3';

export const launchpadhook = async (data) => {
    console.log("login__data", data);
    try {
        let respdata = await axios({
            'method': 'POST',
            'url': API_URL + admin.launchpad,
            data,
        });
        console.log("response", respdata);
        return {
            data: respdata.data
        }
    }
    catch (e) {
        console.log("error", e);
    }
}